const state = {
  produkte: {
    Beilage: {
      'Rota 42.5g': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 12,
            gewicht: 17,
            grundpreis: 1304.27,
            weiteretausend: 37.93,
          },
          {
            seiten: 16,
            gewicht: 23,
            grundpreis: 1348.22,
            weiteretausend: 43.18,
          },
          {
            seiten: 20,
            gewicht: 29,
            grundpreis: 1589.77,
            weiteretausend: 48.45,
          },
          {
            seiten: 24,
            gewicht: 34,
            grundpreis: 1633.64,
            weiteretausend: 53.69,
          },
          {
            seiten: 28,
            gewicht: 40,
            grundpreis: 2049.91,
            weiteretausend: 58.96,
          },
          {
            seiten: 32,
            gewicht: 46,
            grundpreis: 1721.58,
            weiteretausend: 64.20,
          },
          {
            seiten: 36,
            gewicht: 52,
            grundpreis: 2442.72,
            weiteretausend: 75.77,
          },
          {
            seiten: 40,
            gewicht: 57,
            grundpreis: 1983.67,
            weiteretausend: 80.98,
          },
          {
            seiten: 44,
            gewicht: 63,
            grundpreis: 2812.90,
            weiteretausend: 86.31,
          },
          {
            seiten: 48,
            gewicht: 69,
            grundpreis: 2107.99,
            weiteretausend: 91.57,
          },
          {
            seiten: 56,
            gewicht: 80,
            grundpreis: 2344.92,
            weiteretausend: 117.96,
          },
          {
            seiten: 64,
            gewicht: 92,
            grundpreis: 2521.12,
            weiteretausend: 128.51,
          },
        ],
      },
      'Rota 55g': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 8,
            gewicht: 15,
            grundpreis: 1290.47,
            weiteretausend: 38.7,
          },
          {
            seiten: 12,
            gewicht: 22,
            grundpreis: 1349.65,
            weiteretausend: 47.00,
          },
          {
            seiten: 16,
            gewicht: 30,
            grundpreis: 1408.66,
            weiteretausend: 55.27,
          },
          {
            seiten: 20,
            gewicht: 37,
            grundpreis: 1665.37,
            weiteretausend: 63.57,
          },
          {
            seiten: 24,
            gewicht: 45,
            grundpreis: 1724.31,
            weiteretausend: 71.82,
          },
          {
            seiten: 28,
            gewicht: 52,
            grundpreis: 2155.68,
            weiteretausend: 80.11,
          },
          {
            seiten: 32,
            gewicht: 59,
            grundpreis: 1842.46,
            weiteretausend: 88.38,
          },
          {
            seiten: 36,
            gewicht: 67,
            grundpreis: 2578.80,
            weiteretausend: 102.99,
          },
          {
            seiten: 40,
            gewicht: 74,
            grundpreis: 2134.68,
            weiteretausend: 111.19,
          },
          {
            seiten: 44,
            gewicht: 82,
            grundpreis: 2979.25,
            weiteretausend: 119.58,
          },
          {
            seiten: 48,
            gewicht: 89,
            grundpreis: 2288.54,
            weiteretausend: 127.86,
          },
          {
            seiten: 56,
            gewicht: 104,
            grundpreis: 2556.64,
            weiteretausend: 160.30,
          },
          {
            seiten: 64,
            gewicht: 119,
            grundpreis: 2763.15,
            weiteretausend: 176.91,
          },
        ],
      },
      'Rota 80.0g': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 4,
            gewicht: 11,
            grundpreis: 1505.28,
            weiteretausend: 44.99,
          },
          {
            seiten: 8,
            gewicht: 22,
            grundpreis: 1619.98,
            weiteretausend: 63.74,
          },
          {
            seiten: 12,
            gewicht: 32,
            grundpreis: 1734.58,
            weiteretausend: 82.46,
          },
          {
            seiten: 16,
            gewicht: 43,
            grundpreis: 1849.37,
            weiteretausend: 101.23,
          },
          {
            seiten: 20,
            gewicht: 54,
            grundpreis: 2198.25,
            weiteretausend: 119.94,
          },
          {
            seiten: 24,
            gewicht: 65,
            grundpreis: 2312.86,
            weiteretausend: 138.67,
          },
          {
            seiten: 32,
            gewicht: 86,
            grundpreis: 2542.15,
            weiteretausend: 176.14,
          },
          {
            seiten: 40,
            gewicht: 108,
            grundpreis: 3561.18,
            weiteretausend: 221.07,
          },
          {
            seiten: 48,
            gewicht: 130,
            grundpreis: 3813.39,
            weiteretausend: 263.12,
          },
        ],
      },
      'LWC glänzend': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 8,
            gewicht: 17,
            grundpreis: 379.73,
            weiteretausend: 28.68,
          },
          {
            seiten: 12,
            gewicht: 26,
            grundpreis: 472.11,
            weiteretausend: 40.40,
          },
          {
            seiten: 16,
            gewicht: 35,
            grundpreis: 584.32,
            weiteretausend: 49.33,
          },
          {
            seiten: 20,
            gewicht: 44,
            grundpreis: 908.50,
            weiteretausend: 60.19,
          },
          {
            seiten: 24,
            gewicht: 52,
            grundpreis: 1001.79,
            weiteretausend: 69.67,
          },
          {
            seiten: 28,
            gewicht: 61,
            grundpreis: 1168.04,
            weiteretausend: 79.80,
          },
          {
            seiten: 32,
            gewicht: 70,
            grundpreis: 1260.11,
            weiteretausend: 89.04,
          },
          {
            seiten: 40,
            gewicht: 87,
            grundpreis: 1191.39,
            weiteretausend: 111.63,
          },
          {
            seiten: 48,
            gewicht: 105,
            grundpreis: 1331.75,
            weiteretausend: 130.52,
          },
          {
            seiten: 56,
            gewicht: 122,
            grundpreis: 1549.29,
            weiteretausend: 150.35,
          },
          {
            seiten: 64,
            gewicht: 140,
            grundpreis: 1689.14,
            weiteretausend: 169.14,
          },
          {
            seiten: 72,
            gewicht: 158,
            grundpreis: 1893.05,
            weiteretausend: 189.06,
          },
        ],
      },
      'LWC matt': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 8,
            gewicht: 16,
            grundpreis: 374.65,
            weiteretausend: 28.13,
          },
          {
            seiten: 12,
            gewicht: 24,
            grundpreis: 465.34,
            weiteretausend: 39.59,
          },
          {
            seiten: 16,
            gewicht: 32,
            grundpreis: 575.52,
            weiteretausend: 48.25,
          },
          {
            seiten: 20,
            gewicht: 41,
            grundpreis: 895.62,
            weiteretausend: 58.83,
          },
          {
            seiten: 24,
            gewicht: 49,
            grundpreis: 987.09,
            weiteretausend: 68.04,
          },
          {
            seiten: 28,
            gewicht: 57,
            grundpreis: 1150.87,
            weiteretausend: 77.90,
          },
          {
            seiten: 32,
            gewicht: 65,
            grundpreis: 1241.13,
            weiteretausend: 86.87,
          },
          {
            seiten: 40,
            gewicht: 81,
            grundpreis: 1171.44,
            weiteretausend: 108.91,
          },
          {
            seiten: 48,
            gewicht: 97,
            grundpreis: 1308.62,
            weiteretausend: 127.26,
          },
          {
            seiten: 56,
            gewicht: 114,
            grundpreis: 1522.36,
            weiteretausend: 146.56,
          },
          {
            seiten: 64,
            gewicht: 130,
            grundpreis: 1659.04,
            weiteretausend: 164.81,
          },
          {
            seiten: 72,
            gewicht: 146,
            grundpreis: 1859.42,
            weiteretausend: 184.23,
          },
        ],
      },
    },
    Mantel: {
      'Rota 42.5g': {
        type: 'noprint',
        bild: 'produkt-mantel.png',
        kategoriename: 'Mantel',
        verarbeitung: 'bundesland',
      },
      LWC: {
        type: 'single',
        bild: 'produkt-mantel.png',
        kategoriename: 'Mantel',
        verarbeitung: 'mantel',
        items: [
          {
            menge: 5000,
            grundpreis: 598.03,
            weiteretausend: 88.56,
          },
          {
            menge: 10000,
            grundpreis: 875.62,
            weiteretausend: 75.6,
          },
          {
            menge: 20000,
            grundpreis: 1478.32,
            weiteretausend: 70.22,
          },
          {
            menge: 30000,
            grundpreis: 1976.58,
            weiteretausend: 65.16,
          },
          {
            menge: 40000,
            grundpreis: 2575.23,
            weiteretausend: 64.96,
          },
          {
            menge: 50000,
            grundpreis: 2976.78,
            weiteretausend: 59.96,
          },
          {
            menge: 100000,
            grundpreis: 5974.98,
            weiteretausend: 59.96,
          },
          {
            menge: 200000,
            grundpreis: 11472.57,
            weiteretausend: 57.47,
          },
          {
            menge: 300000,
            grundpreis: 17219.12,
            weiteretausend: 57.47,
          },
        ],
      },
    },
    'Tip on Card': {
      none: {
        bild: 'produkt-tiponcard.png',
        kategoriename: 'Tip on Card',
        type: 'single',
        verarbeitung: 'tipon',
        items: [
          {
            menge: 5000,
            grundpreis: 243.41,
            weiteretausend: 12.54,
          },
          {
            menge: 10000,
            grundpreis: 296.46,
            weiteretausend: 12.54,
          },
          {
            menge: 20000,
            grundpreis: 403.38,
            weiteretausend: 12.54,
          },
          {
            menge: 30000,
            grundpreis: 510.27,
            weiteretausend: 12.54,
          },
          {
            menge: 40000,
            grundpreis: 616.39,
            weiteretausend: 12.54,
          },
          {
            menge: 50000,
            grundpreis: 622.95,
            weiteretausend: 10.74,
          },
          {
            menge: 100000,
            grundpreis: 1140.31,
            weiteretausend: 10.72,
          },
          {
            menge: 200000,
            grundpreis: 1926.77,
            weiteretausend: 9.7,
          },
          {
            menge: 300000,
            grundpreis: 2702.18,
            weiteretausend: 9.2,
          },
          {
            menge: 400000,
            grundpreis: 3430.04,
            weiteretausend: 8.77,
          },
        ],
      },
    },
    Flyer: {
      none: {
        type: 'single',
        bild: 'produkt-flyer.png',
        kategoriename: 'Flyer',
        verarbeitung: 'flyer',
        items: [
          {
            menge: 5000,
            grundpreis: 366.97,
            weiteretausend: 36.14,
          },
          {
            menge: 10000,
            grundpreis: 528.35,
            weiteretausend: 33.26,
          },
          {
            menge: 20000,
            grundpreis: 795.2,
            weiteretausend: 29.32,
          },
          {
            menge: 30000,
            grundpreis: 1065.16,
            weiteretausend: 27.93,
          },
          {
            menge: 40000,
            grundpreis: 1283.49,
            weiteretausend: 26.12,
          },
          {
            menge: 50000,
            grundpreis: 1559.53,
            weiteretausend: 26.05,
          },
          {
            menge: 100000,
            grundpreis: 2733.7,
            weiteretausend: 24.09,
          },
          {
            menge: 200000,
            grundpreis: 5004.83,
            weiteretausend: 22.24,
          },
          {
            menge: 300000,
            grundpreis: 7228.43,
            weiteretausend: 22.24,
          },
        ],
      },
    },
    Folder: {
      none: {
        type: 'single',
        bild: 'produkt-folder.png',
        kategoriename: 'Folder',
        verarbeitung: 'folder',
        items: [
          {
            menge: 5000,
            grundpreis: 528.64,
            weiteretausend: 75.14,
          },
          {
            menge: 10000,
            grundpreis: 800.09,
            weiteretausend: 66.9,
          },
          {
            menge: 20000,
            grundpreis: 1305.49,
            weiteretausend: 60.96,
          },
          {
            menge: 30000,
            grundpreis: 1946.91,
            weiteretausend: 60.69,
          },
          {
            menge: 40000,
            grundpreis: 2424.29,
            weiteretausend: 60.69,
          },
          {
            menge: 50000,
            grundpreis: 2829.11,
            weiteretausend: 44.1,
          },
          {
            menge: 100000,
            grundpreis: 4674.53,
            weiteretausend: 40.95,
          },
          {
            menge: 200000,
            grundpreis: 8094.95,
            weiteretausend: 37.8,
          },
          {
            menge: 300000,
            grundpreis: 11874.95,
            weiteretausend: 37.8,
          },
        ],
      },
    },
  },
  verarbeitung: {
    beilagen: {
      wochentag: [
        {
          grammatur: 0,
          preisunter80k: 118.8,
          preisab80k: 117,
        },
        {
          grammatur: 20,
          preisunter80k: 124.7,
          preisab80k: 122.9,
        },
        {
          grammatur: 30,
          preisunter80k: 130.6,
          preisab80k: 128.7,
        },
        {
          grammatur: 40,
          preisunter80k: 136.5,
          preisab80k: 134.5,
        },
        {
          grammatur: 50,
          preisunter80k: 142.4,
          preisab80k: 140.3,
        },
        {
          grammatur: 60,
          preisunter80k: 148.3,
          preisab80k: 146.1,
        },
        {
          grammatur: 70,
          preisunter80k: 154.3,
          preisab80k: 152,
        },
      ],
      sonntag: [
        {
          grammatur: 0,
          preisunter80k: 123.9,
          preisab80k: 122.1,
        },
        {
          grammatur: 20,
          preisunter80k: 130.1,
          preisab80k: 128.2,
        },
        {
          grammatur: 30,
          preisunter80k: 136.2,
          preisab80k: 134.2,
        },
        {
          grammatur: 40,
          preisunter80k: 142.3,
          preisab80k: 140.2,
        },
        {
          grammatur: 50,
          preisunter80k: 148.5,
          preisab80k: 146.3,
        },
        {
          grammatur: 60,
          preisunter80k: 154.6,
          preisab80k: 152.3,
        },
        {
          grammatur: 70,
          preisunter80k: 160.8,
          preisab80k: 158.4,
        },
      ],
    },
    mantel: {
      wochentag: [
        {
          grammatur: 0,
          preisunter80k: 415.8,
          preisab80k: 409.5,
        },
        {
          grammatur: 20,
          preisunter80k: 436.45,
          preisab80k: 430.15,
        },
        {
          grammatur: 30,
          preisunter80k: 457.1,
          preisab80k: 450.45,
        },
      ],
      sonntag: [
        {
          grammatur: 0,
          preisunter80k: 433.65,
          preisab80k: 427.35,
        },
        {
          grammatur: 20,
          preisunter80k: 455.35,
          preisab80k: 448.7,
        },
        {
          grammatur: 30,
          preisunter80k: 476.7,
          preisab80k: 469.7,
        },
      ],
    },
    tipon: {
      perforation: {
        keine: 0,
        '1x': 400,
        '2x': 500,
      },
      titelseite: {
        wochentag: {
          preisunter80k: 166.32,
          preisab80k: 163.8,
        },
        sonntag: {
          preisunter80k: 173.46,
          preisab80k: 170.94,
        },
      },
      u4: {
        wochentag: {
          preisunter80k: 130.68,
          preisab80k: 128.7,
        },
        sonntag: {
          preisunter80k: 136.29,
          preisab80k: 134.31,
        },
      },
    },
    flyer: {
      wochentag: [
        {
          grammatur: 20,
          preisunter80k: 118.8,
          preisab80k: 117,
        },
      ],
      sonntag: [
        {
          grammatur: 20,
          preisunter80k: 123.9,
          preisab80k: 122.1,
        },
      ],
    },
    folder: {
      wochentag: [
        {
          grammatur: 20,
          preisunter80k: 118.8,
          preisab80k: 117,
        },
      ],
      sonntag: [
        {
          grammatur: 20,
          preisunter80k: 123.9,
          preisab80k: 122.1,
        },
      ],
    },
    bundesland: {
      zweiseitig: {
        kombi: {
          wochentag: 102370.0,
          sonntag: 107488.5,
        },
        steiermark: {
          wochentag: 69611.6,
          sonntag: 73092.18,
        },
        kaernten: {
          wochentag: 34805.8,
          sonntag: 36546.09,
        },
      },
      vierseitig: {
        kombi: {
          wochentag: 115160.7,
          sonntag: 120918.74,
        },
        steiermark: {
          wochentag: 78309.28,
          sonntag: 82224.74,
        },
        kaernten: {
          wochentag: 39154.64,
          sonntag: 41112.37,
        },
      },
    },
  },
};

const getters = {};
const mutations = {};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
