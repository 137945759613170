import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import store from './store';
import routes from './router';
import axios, { AxiosResponse } from 'axios';
import tippy, { Props } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import VueSmoothScroll from 'vue3-smooth-scroll';

//import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import menu from '@/store/menu';
import { emitter } from './emitter';

interface NavItem {
  path: string;
  name: string;
  component: any;
  nested: boolean;
  children?: Array<NavItem>;
}

// function convertedRoutes(list: Array<NavItem>): Array<RouteRecordRaw> {
//   return list.map((item) => {
//     if (item.children) {
//       item.children = convertedRoutes(item.children) as Array<NavItem>;
//     }
//     return convert(item);
//   });
// }

// function convert(item: NavItem): RouteRecordRaw {
//   const conv: RouteRecordRaw = {
//     path: item.path,
//     name: item.name,
//     component: () => import('@/views/' + item.component),
//   };

//   if (item.children) {
//     conv.children = item.children;
//   }

//   return conv;
// }

// axios.get('https://tarif.smd-digital.at/api/').then((response: AxiosResponse) => {

// menu.state.items.raw = response.data.message.raw;
// menu.state.items.flat = response.data.message.flat;
// menu.state.items.hierarchical = response.data.message.hierarchical;

// });

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.afterEach((to, from) => {
  /* eslint-disable */
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
   //@ts-ignore
  window.dataLayer.push({
    event: 'Virtual Page View',
     //@ts-ignore
    page_path: document.location.href.match(/https?:\/\/.*?(\/.*)/)[1],
  });
  /* eslint-enable */
});

createApp(App)
  .provide('emitter', emitter)
  .use(store)
  .use(router)
  .use(VueSmoothScroll)
  .directive('tooltip', {
    mounted(el, binding) {
      tippy(el, binding.value as Partial<Props>);
    },
  })
  .mount('#app');
