import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import(/* webpackChunkName: "Werbung" */ '../views/Kontakt.vue'),
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "Index" */ '../views/_Container.vue'),
    children: [
      {
        path: '/test',
        name: 'Test',
        component: () => import(/* webpackChunkName: "Startseite" */ '../views/Test.vue'),
        meta: { pageid: 1 },
      },

      {
        path: '/',
        name: 'Startseite',
        component: () => import(/* webpackChunkName: "Startseite" */ '../views/Startseite.vue'),
      },
      {
        path: '/print/platzierungen',
        name: 'Platzierungen',
        component: () => import(/* webpackChunkName: "Platzierungen" */ '../views/print/Platzierungen.vue'),
      },
      {
        path: '/print/regional',
        name: 'Regional',
        component: () => import(/* webpackChunkName: "Regional" */ '../views/print/Regional.vue'),
      },
      {
        path: '/print/native-content',
        name: 'NativeContent',
        component: () => import(/* webpackChunkName: "NativeContent" */ '../views/print/NativeContent.vue'),
      },
      {
        path: '/allgemeines/allgemeine-informationen',
        name: 'Allgemeine Informationen',
        component: () => import(/* webpackChunkName: "Allgemeines" */ '../views/allgemeines/AllgemeineInformationen.vue'),
      },
      {
        path: '/allgemeines/mediadaten',
        name: 'Mediadaten',
        component: () => import(/* webpackChunkName: "Mediadaten" */ '../views/allgemeines/Mediadaten.vue'),
      },
      {
        path: '/allgemeines/technische-daten',
        name: 'Technische Daten',
        component: () => import(/* webpackChunkName: "TechnischeDaten" */ '../views/allgemeines/TechnischeDaten.vue'),
      },
      {
        path: '/allgemeines/agb',
        name: 'AGB',
        component: () => import(/* webpackChunkName: "AGB" */ '../views/allgemeines/AGB.vue'),
      },
      {
        path: '/digital/display-werbung',
        name: 'DisplayWerbung',
        component: () => import(/* webpackChunkName: "DisplayWerbung" */ '../views/digital/DisplayWerbung.vue'),
      },
      {
        path: '/digital/e-paper',
        name: 'EPaper',
        component: () => import(/* webpackChunkName: "EPaper" */ '../views/digital/EPaper.vue'),
      },
      {
        path: '/digital/content-teaser',
        name: 'ContentTeaserPerformancePaket',
        component: () => import(/* webpackChunkName: "ContentTeaserPerformancePaket" */ '../views/digital/ContentTeaserPerformancePaket.vue'),
      },
      {
        path: '/digital/specials',
        name: 'Specials',
        component: () => import(/* webpackChunkName: "Specials" */ '../views/digital/Specials.vue'),
      },
      {
        path: '/digital/kleine-extra',
        name: 'KleineExtra',
        component: () => import(/* webpackChunkName: "KleineExtra" */ '../views/digital/KleineExtra.vue'),
      },
      {
        path: '/digital/erweiterung-digitales-prospekt',
        name: 'ErweiterungDigitalesProspekt',
        component: () => import(/* webpackChunkName: "ErweiterungDigitalesProspekt" */ '../views/digital/ErweiterungDigitalesProspekt.vue'),
      },
      {
        path: '/digital/newsletter',
        name: 'Newsletter',
        component: () => import(/* webpackChunkName: "Newsletter" */ '../views/digital/Newsletter.vue'),
      },
      {
        path: '/digital/native',
        name: 'Native',
        component: () => import(/* webpackChunkName: "Native" */ '../views/digital/Native.vue'),
      },
      {
        path: '/beilagensonderwerbeformen/beilagen',
        name: 'Beilagen',
        component: () => import(/* webpackChunkName: "Beilagen" */ '../views/beilagensonderwerbeformen/Beilagen.vue'),
      },
      {
        path: '/digital/digitales-prospekt',
        name: 'Digitales Prospekt',
        component: () => import(/* webpackChunkName: "DigitalesProspekt" */ '../views/digital/DigitalesProspekt.vue'),
      },
      {
        path: '/beilagensonderwerbeformen/mitdruck',
        name: 'Mitdruck',
        component: () => import(/* webpackChunkName: "Mitdruck" */ '../views/beilagensonderwerbeformen/Mitdruck.vue'),
      },
      {
        path: '/beilagensonderwerbeformen/mantel',
        name: 'Mantel',
        component: () => import(/* webpackChunkName: "Mantel" */ '../views/beilagensonderwerbeformen/Mantel.vue'),
      },
      {
        path: '/beilagensonderwerbeformen/tiponcard',
        name: 'Tiponcard',
        component: () => import(/* webpackChunkName: "Tiponcard" */ '../views/beilagensonderwerbeformen/Tiponcard.vue'),
      },
      {
        path: '/allgemeines/spezifikationen',
        name: 'Spezifikationen',
        component: () => import(/* webpackChunkName: "Spezifikationen" */ '../views/allgemeines/Spezifikationen.vue'),
      },
      {
        path: '/branchenloesungen/themenschwerpunkte',
        name: 'Themenschwerpunkte',
        component: () => import(/* webpackChunkName: "Themenschwerpunkte" */ '../views/branchenloesungen/Themenschwerpunkte.vue'),
      },
      {
        path: '/branchenloesungen/beruf-bildung',
        name: 'BildungBeruf',
        component: () => import(/* webpackChunkName: "BildungBeruf" */ '../views/branchenloesungen/BildungBeruf.vue'),
      },
      {
        path: '/branchenloesungen/wohnen',
        name: 'Wohnen',
        component: () => import(/* webpackChunkName: "Wohnen" */ '../views/branchenloesungen/Wohnen.vue'),
      },
      {
        path: '/branchenloesungen/VeranstaltungenKulinarik',
        name: 'VeranstaltungenKulinarik',
        component: () => import(/* webpackChunkName: "VeranstaltungenKulinarik" */ '../views/branchenloesungen/VeranstaltungenKulinarik.vue'),
      },
      {
        path: '/branchenloesungen/moblitaet',
        name: 'Mobilitaet',
        component: () => import(/* webpackChunkName: "Mobilitaet" */ '../views/branchenloesungen/Mobilitaet.vue'),
      },
      {
        path: '/branchenloesungen/reise',
        name: 'Reise',
        component: () => import(/* webpackChunkName: "Reise" */ '../views/branchenloesungen/Reise.vue'),
      },
      {
        path: '/print/exklusivplatzierung',
        name: 'Exklusivplatzierung',
        component: () => import(/* webpackChunkName: "Exklusivplatzierung" */ '../views/print/Exklusivplatzierung.vue'),
      },
      {
        path: '/print/topplatzierung-politik',
        name: 'TopplatzierungPolitik',
        component: () => import(/* webpackChunkName: "TopplatzierungPolitik" */ '../views/print/TopplatzierungPolitik.vue'),
      },
      {
        path: '/print/themenplatzierung',
        name: 'Themenplatzierung',
        component: () => import(/* webpackChunkName: "Themenplatzierung" */ '../views/print/Themenplatzierung.vue'),
      },
      {
        path: '/print/basisplatzierung',
        name: 'Basisplatzierung',
        component: () => import(/* webpackChunkName: "Basisplatzierung" */ '../views/print/Basisplatzierung.vue'),
      },
      {
        path: '/junge-zielgruppe/kinderzeitung',
        name: 'Kinderzeitung',
        component: () => import(/* webpackChunkName: "Kinderzeitung" */ '../views/junge-zielgruppe/Kinderzeitung.vue'),
      },
      {
        path: '/junge-zielgruppe/pausenzeitung',
        name: 'Pausenzeitung',
        component: () => import(/* webpackChunkName: "Pausenzeitung" */ '../views/junge-zielgruppe/Pausenzeitung.vue'),
      },
      {
        path: '/junge-zielgruppe/futter',
        name: 'Futter',
        component: () => import(/* webpackChunkName: "Futter" */ '../views/junge-zielgruppe/Futter.vue'),
      },
      {
        path: '/calculator',
        name: 'calculator',
        component: () => import(/* webpackChunkName: "about" */ '../views/Calculator.vue'),
      },
      {
        path: '/zielgruppenrechner',
        name: 'zielgruppenrechner',
        component: () => import(/* webpackChunkName: "zielgruppenrechner" */ '../views/Zielgruppenrechner.vue'),
      },
      {
        path: '/unser-team',
        name: 'UnserTeam',
        component: () => import(/* webpackChunkName: "UnserTeam" */ '../views/UnserTeam.vue'),
      },
      {
        path: '/kontakt-formular',
        name: 'KontaktFormular',
        component: () => import(/* webpackChunkName: "KontaktFormular" */ '../views/KontaktFormular.vue'),
      },
    ],
  },
];

export default routes;
